@import "../../styles/_chunk";

.hit-mega-card {
  background-color: $card-background-dark;
  border-radius: $border-radius;
  margin-bottom: 1.25rem;
  width: 100%;

  @include theme(light) {
    background-color: map-get($light-theme, card-background);
    box-shadow: map-get($light-theme, card-box-shadow);
    color: map-get($light-theme, font-color);
  }

  @include media-breakpoint-up(md) {
    margin-bottom: 1.75rem;
  }

  @include media-breakpoint-up(xl) {
    margin-bottom: 2.5rem;
  }

  .card-content-container {
    position: relative;
    display: flex;
    flex-direction: column;

    @include media-breakpoint-up(md) {
      flex-direction: row;
    }

    .image-container {
      width: 100%;
      flex-shrink: 0;

      @include media-breakpoint-up(md) {
        width: 48%;
      }

      @include media-breakpoint-up(lg) {
        width: 45%;
      }

      @include media-breakpoint-up(xl) {
        width: 42%;
      }

      .responsive-image {
        border-top-left-radius: $border-radius;
        border-top-right-radius: $border-radius;

        @include media-breakpoint-up(md) {
          border-bottom-left-radius: $border-radius;
          border-top-right-radius: 0;
        }

        img {
          border-top-left-radius: $border-radius;
          border-top-right-radius: $border-radius;
          overflow: hidden;

          @include media-breakpoint-up(md) {
            border-bottom-left-radius: $border-radius;
            border-top-right-radius: 0;
          }
        }
      }
    }

    .content-container {
      display: flex;
      flex-direction: column;
      width: 100%;
      position: relative;
    }

    .name-description-container {
      display: none;
      position: relative;

      @include media-breakpoint-up(md) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        flex-grow: 1;
        padding: 1rem;
        padding-bottom: 0;
      }

      .product-name {
        font-weight: 400;
        margin-bottom: 0.5rem;
        white-space: normal;

        a {
          color: $text-white;

          @include theme(light) {
            color: map-get($light-theme, font-color);
          }
        }
      }

      .top-product-description {
        font-size: 14px;
        color: $text-muted;

        @include media-breakpoint-up(lg) {
          font-size: 15px;
        }
      }

      .timer-progress-container {
        display: flex;
        flex-direction: column-reverse;
        margin-top: 1.25rem;

        .flash-timer-container {
          display: flex;
          align-items: center;
          width: 100%;
          margin-top: 0.25rem;
          font-size: 12px;

          @include media-breakpoint-up(xl) {
            font-size: 14px;
          }

          @include media-breakpoint-up(xxl) {
            font-size: 15px;
          }

          .timer-text {
            text-transform: uppercase;
            margin-right: 5px;
            font-weight: 700;
          }

          .sd-timer {
            text-transform: uppercase;
            font-weight: 700;

            .colon {
              padding: 0 2px;
            }
          }
        }

        .progress-container {
          .progress {
            height: 10px;

            @include theme(light) {
              background-color: $grey-3;
            }

            @include media-breakpoint-up(xl) {
              height: 15px;
            }

            @include media-breakpoint-up(xxl) {
              height: 20px;
            }

            .progress-green {
              background-color: $progress-green;
            }

            .progress-yellow {
              background-color: $progress-yellow;
            }

            .progress-red {
              background-color: $progress-red;
            }
          }
        }
      }
    }

    .price-icons-container {
      position: relative;
      font-size: 0.813rem;
      padding-left: 0.5rem;
      padding-right: 0.5rem;
      height: $hit-card-stripe-height;
      background-color: $card-background-dark;
      transition: all ease 0.1s;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      width: 100%;
      border-bottom-right-radius: $border-radius;

      @include theme(light) {
        background-color: map-get($light-theme, card-background);
      }

      @include media-breakpoint-up(md) {
        justify-content: space-between;
        padding-left: 1rem;
        padding-right: 1rem;
        padding-bottom: 1rem;
      }

      .card-os-icons {
        display: none;
        font-size: 12px;

        @include theme(light) {
          color: map-get($light-theme, card-icons-color);
        }
      }

      .drm-container {
        display: none;

        @include media-breakpoint-up(md) {
          display: block;
        }
      }

      .card-price {
        &:hover {
          color: $text-white;
          background-color: transparent;
        }
      }
    }
  }

  .top-product-overlay-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 5;
    padding-bottom: $hit-card-stripe-height;
    display: none;

    @include media-breakpoint-up(md) {
      display: block;
    }
  }

  .top-product-overlay {
    height: 100%;
    opacity: 0;
    transition: all ease 0.1s;

    .wishlist-button {
      position: absolute;
      right: 8px;
      top: 8px;
      color: $grey-8;
      z-index: 8;

      button {
        margin: 0;
        padding: 0;
        width: auto;
        font-size: 12px;
      }

      button.wishlist-add {
        color: $primary-core !important;
        min-width: auto;
      }

      button.wishlist-remove {
        color: $grey-8 !important;
        min-width: auto;

        &:hover {
          color: $primary-core !important;
        }
      }
    }

    .overlay-content {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      h6 {
        font-size: 0.875rem;
        line-height: 1.2;
        padding: 0.2rem 1rem 0.1rem;
        text-shadow: 1px 1px 10px $black, 1px 1px 10px $black;
        text-decoration: none;
        color: $text-white;
        margin-bottom: 0;
      }

      .hit-card-buttons-container {
        display: flex;
        justify-content: center;
        margin: 0.5rem 0;

        .btn {
          display: flex;
          align-items: center;
          justify-content: center;
          min-height: 36px;

          svg {
            margin-right: 0.125rem;
          }
        }
      }

      .btn {
        min-width: 4.75rem;
        padding: 0.475rem 0.375rem 0.5rem;
        margin: 1px 4px;
        cursor: pointer;
        font-size: 0.6875rem;
        font-weight: 700;
        text-transform: uppercase;
        letter-spacing: 0.25px;
        text-align: center;
        z-index: -10;

        @include media-breakpoint-up(xl) {
          font-size: 0.75rem;
          margin: 1px 5px;
        }
      }

      .quicklook-container {
        .btn-link {
          color: $text-white;
          padding: 0;
          font-weight: 300;
          text-decoration: underline;
          text-transform: none;

          &:hover {
            @include brand-property(color, brand-core);
          }
        }
      }
    }
  }

  @include hover-supported() {
    color: $white !important;

    .price-icons-container {
      background-color: rgba($black, 1) !important;

      .card-os-icons {
        display: flex;
        align-items: center;

        @include theme(light) {
          color: $white;
        }
      }

      .drm-container {
        display: none;
      }

      .card-price-container {
        .card-saving {
          background-color: $card-background-dark;

          @include theme(light) {
            color: $white;
          }
        }
      }
    }

    .top-product-overlay {
      background-color: rgba($black, 1);
      opacity: 1;
      border-top-right-radius: $border-radius;

      .btn {
        z-index: 6;
      }
    }
  }

  &.card-light-theme {
    background-color: map-get($light-theme, card-background);
    box-shadow: map-get($light-theme, card-box-shadow);
    color: map-get($light-theme, font-color);
    font-weight: 400;

    @media #{$retina-screen} {
      font-weight: inherit;
    }

    .card-content-container {
      .name-description-container {
        .product-name {
          a {
            color: $grey-8;
          }
        }

        .timer-progress-container {
          .progress-container {
            .progress {
              background-color: map-get($light-theme, saving-percentage-background);
            }
          }
        }
      }

      .price-icons-container {
        background-color: map-get($light-theme, card-background);

        .card-saving {
          background-color: map-get($light-theme, saving-percentage-background);
          color: map-get($light-theme, font-color);
        }
      }
    }

    @include hover-supported() {
      .card-content-container {
        .price-icons-container {
          .card-saving {
            background-color: $grey-11;
            color: $text-white;
          }
        }
      }
    }
  }
}
